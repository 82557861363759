import React, { useEffect, useRef, useState } from "react";
import { BiUpArrowAlt } from "react-icons/bi";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import styles from "../css/Support.module.scss";

// const socket = io("http://localhost:4000/socket_connection");
// const socket = io("");
// const socket = io("");
//http://localhost:3666/socket_connection

const SupportChartPopup = ({ setShowChat, ticketData }) => {
	const { userId } = useSelector((state) => state.auth);
	const [text, setText] = useState("");
	const [chat, setChat] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const chatContainerRef = useRef();
	const room = ticketData.ticketNumber;
	const date = new Date(ticketData.createdAt);
	const month = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	// useEffect(() => {
	// 	const domNode = chatContainerRef.current;
	// 	domNode.scrollTop = domNode.scrollHeight;
	// }, [chat]);

	// useEffect(() => {
	// 	socket.emit("joinRoom", room);
	// 	setIsLoading(true);

	// 	socket.on("chatHistory", (payload) => {
	// 		setChat(payload);
	// 		setIsLoading(false);
	// 	});
	// }, [ticketData]);

	// useEffect(() => {
	// 	socket.on(`message_${room}`, (payload) => {
	// 		setChat((pre) => [...pre, payload]);
	// 	});
	// }, [socket]);

	const handelSend = () => {
		if (text === "") return;
		const data = {
			room,
			sender: userId,
			message: text,
		};
		// socket.emit("chatMessage", data);
		setChat((pre) => [
			...pre,
			{
				room,
				sender: userId,
				message: text,
				updatedAt: new Date()
			},
		]);
		setText("");
	};

	return (
		<div className={styles.SupportChartPopupMainContainer}>
			<h5 onClick={() => setShowChat(false)}>◄ Support</h5>
			<h6>Recent</h6>
			<div className={styles.TicketBox}>
				<div className={styles.left}>
					<p>Ticket No #{ticketData.ticketNumber}</p>
					<h2>{ticketData.ticketTitle}</h2>
					<p>
						{date.getDate()} {month[date.getMonth()]} {date.getFullYear()}{" "}
					</p>
				</div>
				<div className={`${styles.right} ${!ticketData.ticketStaus && styles.close} `}>
					{ticketData.ticketStaus ? "Active" : "Close"}
				</div>
			</div>
			<div className={styles.ChatContainer} ref={chatContainerRef}>
				{isLoading && "Loading..."}
				{chat.map((data, index) => {
					const date = new Date(data.updatedAt);
					if (data.sender === userId) {
						return (
							<div key={index} className={styles.MyChat}>
								<p>{data.message}</p>
								<span>
									{date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:
									{date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}
								</span>
							</div>
						);
					} else {
						return (
							<div key={index} className={styles.YourChat}>
								<p>{data.message}</p>
								<span>{data.time}</span>
							</div>
						);
					}
				})}
				{/* MyChat */}
				{/* <div className={styles.MyChat}>
					<p>Need Help</p>
					<span>10:30AM</span>
				</div> */}
				{/* Your Chat */}
				{/* <div className={styles.YourChat}>
					<p>Let us check</p>
					<span>10:30AM</span>
				</div> */}
			</div>
			{ticketData.ticketStaus && (
				<div className={styles.InputContainer}>
					<input
						type="text"
						placeholder="Type something"
						value={text}
						onChange={(e) => setText(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === "Enter") handelSend();
						}}
					/>
					<div className={styles.Icon} onClick={handelSend}>
						<BiUpArrowAlt size={25} />
					</div>
				</div>
			)}
		</div>
	);
};

export default SupportChartPopup;
