import "react-alice-carousel/lib/alice-carousel.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./css/App.scss";
import "./css/Responsive.scss";

import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { setInLocalStorage } from "./Redux/slices/AuthSlice";
import AboutUs from "./User/AboutUs";
import Account from "./User/Account";
import Album from "./User/Album/Album";
import ChangePassword from "./User/ChangePassword";
import ForgetPassword from "./User/ForgetPassword";
import ForgetPasswordOTP from "./User/ForgetPasswordOTP";
import GetStarted from "./User/GetStarted";
import HomePage from "./User/HomePage";
import Instructor from "./User/Instructor";
import LandingPage from "./User/LandingPage";
import Login from "./User/Login";
import MobileLogin from "./User/MobileLogin";
import ProfileUpdate from "./User/ProfileUpdate";
import Register from "./User/Register";
import Register2 from "./User/Register2";
import TermsCondition from "./User/TermsCondition";
import Verification from "./User/Verification";
import Privacy from "./User/privacyPolicy";
import Payment from "./components/Payment/Payment";
import PaymentCancel from "./components/Payment/PaymentCancel";
import PaymentSuccessful from "./components/Payment/PaymentSuccessful";
import SharePostScreen from "./components/SharePostScreen";
import Support from "./components/Support";
import UsersProfile from "./components/UsersProfile";
import PaymentVerify from "./components/Payment/PaymentVerify";


function App() {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setInLocalStorage());
	}, []);

	const [isOnline, setIsOnline] = useState(navigator.onLine);
	const toastId = useRef(null);

	useEffect(() => {
		// Update network status
		const handleStatusChange = () => {
			setIsOnline(navigator.onLine);
		};

		// Listen to the online status
		window.addEventListener("online", handleStatusChange);

		// Listen to the offline status
		window.addEventListener("offline", handleStatusChange);

		// Specify how to clean up after this effect for performance improvment
		return () => {
			window.removeEventListener("online", handleStatusChange);
			window.removeEventListener("offline", handleStatusChange);
		};
	}, [isOnline]);

	if (!isOnline)
		toastId.current = toast.error("You are Offline !!", {
			position: "top-center",
			autoClose: false,
			closeOnClick: true,
			theme: "colored",
		});
	else toast.dismiss(toastId.current);

	return (
		<BrowserRouter>
			<ToastContainer
				position="top-center"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover={false}
				theme="dark"
			/>
			<Routes>
				<Route exact path="/" element={<LandingPage />} />
				<Route exact path="/getStarted" element={<GetStarted />} />
				<Route exact path="/register1" element={<Register />} />
				<Route exact path="/register2" element={<Register2 />} />
				<Route exact path="/login" element={<Login />} />
				<Route exact path="/mobile-login" element={<MobileLogin />} />
				<Route exact path="/forget-password" element={<ForgetPassword />} />
				<Route exact path="/forget-password-otp" element={<ForgetPasswordOTP />} />
				<Route exact path="/changePassword" element={<ChangePassword />} />
				<Route exact path="/verification" element={<Verification />} />
				<Route exact path="/dashboard" element={<HomePage />} />
				<Route exact path="/instructor" element={<Instructor />} />
				<Route exact path="/account" element={<Account />} />
				<Route exact path="/aboutUs" element={<AboutUs />} />
				<Route exact path="/privacy" element={<Privacy />} />
				<Route exact path="/support" element={<Support />} />
				<Route exact path="/terms-condition" element={<TermsCondition />} />
				<Route exact path="/album" element={<Album />} />
				<Route exact path="/album/:id" element={<Album />} />
				<Route exact path="/profile-update" element={<ProfileUpdate />} />
				<Route exact path="/share/:id" element={<SharePostScreen />} />
				<Route exact path="/profile/:id" element={<UsersProfile />} />
				<Route exact path="/payment/:cardId/:token/:amount/:premium" element={<Payment />} />
				<Route exact path="/payment" element={<Payment />} />
				<Route exact path="/payment/successful" element={<PaymentSuccessful />} />
				<Route exact path="/payment/cancel" element={<PaymentCancel />} />
				<Route exact path="/payment/verify/:id" element={<PaymentVerify />} />
				

				

				{/* 
				<Route exact path="/Professional-forgetPassword" element={ProfessionalForgetPassword} />
				<Route exact path="/Professional-verification" element={ProfessionalVerification} />
				<Route exact path="/Professional-changePassword" element={ProfessionalChangePassword} />
				<Route exact path="/Professional-album" element={ProfessionalAlbum} />
				*/}
			</Routes>
		</BrowserRouter>
	);
}

export default App;
