import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { setMessage } from "../Redux/slices/TempSlice";
import axios from "../components/Hooks/axios";
import styles from "../css/MessagePopup.module.scss";
import { createNotification } from "./Hooks/CreateNotification";

// const socket = io("");
//http://localhost:3666/socket_connection

const MessagePopup = () => {
	const dispatch = useDispatch();
	const { messageUserId } = useSelector((state) => state.temp);
	const { userId, user } = useSelector((state) => state.auth);
	const [userData, setUserData] = useState(null);
	const chatContainerRef = useRef();
	const [text, setText] = useState("");
	const [chat, setChat] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	const generateConsistentName = (id1, id2) => {
		const sortedIds = [id1.toString(), id2.toString()].sort();
		return sortedIds.join("");
	};

	const room = generateConsistentName(userId, messageUserId);

	useEffect(() => {
		const domNode = chatContainerRef.current;
		domNode.scrollTop = domNode.scrollHeight;
	}, [chat]);

	useEffect(() => {
		axios
			.get(`/auth/get-user-details?user_id=${messageUserId}`)
			.then(({ data }) => {
				if (data.status) {
					setUserData(data.data.data);
				}
			})
			.catch((e) => console.log(e));
	}, []);

	// useEffect(() => {
	// 	socket.emit("joinRoom", room);
	// 	setIsLoading(true);

	// 	socket.on("chatHistory", (payload) => {
	// 		setChat(payload);
	// 		setIsLoading(false);
	// 	});
	// }, [messageUserId]);

	// useEffect(() => {
	// 	socket.on(`message_${room}`, (payload) => {
	// 		setChat((pre) => [...pre, payload]);
	// 	});
	// }, [socket]);

	const handelSend = () => {
		if (text === "") return;
		const data = {
			room,
			sender: userId,
			message: text,
		};
		createNotification(messageUserId, `Message-${userId}`, `${user.full_name} started a conversation with you`); // NOTE: add image url
		// socket.emit("chatMessage", data);
		setChat((pre) => [
			...pre,
			{
				room,
				sender: userId,
				message: text,
				updatedAt: new Date(),
			},
		]);
		setText("");
	};

	return (
		<div className={styles.MessagePopupBackground} onClick={() => dispatch(setMessage({ open: false, id: "", name: "" }))}>
			<div className={styles.MessagePopup} onClick={(e) => e.stopPropagation()}>
				<div className={styles.Top}>
					<img src={userData?.pic} alt="profile" />
					<h1
						onClick={() => {
							dispatch(setMessage({ open: false, id: "", name: "" }));
							navigate(`/profile/${userData._id}`);
						}}>
						{userData?.full_name}
					</h1>
				</div>

				<div className={styles.Middle} ref={chatContainerRef}>
					{isLoading && "Loading..."}
					{chat.map((data, index) => {
						const date = new Date(data.updatedAt);
						if (data.sender === userId) {
							return (
								<div key={index} className={styles.My}>
									<p>{data.message}</p>
									<span>
										{date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:
										{date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}
									</span>
								</div>
							);
						} else {
							return (
								<div key={index} className={styles.You}>
									<p>{data.message}</p>
									<span>
										{date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:
										{date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}
									</span>
								</div>
							);
						}
					})}
				</div>

				<div className={styles.Bottom}>
					<input
						type="text"
						placeholder="Type anything..."
						value={text}
						onChange={(e) => setText(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === "Enter") handelSend();
						}}
					/>
					<button onClick={handelSend}>{">"}</button>
				</div>
			</div>
		</div>
	);
};

export default MessagePopup;
