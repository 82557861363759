import axios from "axios";

const Instance = axios.create({
	// baseURL: "https://businesspersonapp.emagz.live/api/v1",
	baseURL: process.env.REACT_APP_BASE_URL,
	headers: {
		authorization: localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token"),
	},
});

export default Instance;
