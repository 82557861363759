import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { setIsFollow, setMessage } from "../Redux/slices/TempSlice";
import Header from "../User/header.component";
import axios from "../components/Hooks/axios";
import styles from "../css/ProfessionalAccount.module.scss";
import userDefaultImage from "../images/userDefaultImage.jpg";
import FollowList from "./FollowList";
import { createNotification } from "./Hooks/CreateNotification";
import MessagePopup from "./MessagePopup";
import PostForUser from "./PostForUser";

const UsersProfile = () => {
	const navigate = useNavigate();
	const { token, user, userId } = useSelector((state) => state.auth);
	const { isFollow, isMessageOpen } = useSelector((state) => state.temp);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!token) {
			return navigate("/");
		}
	}, []);

	let profileId = useParams();
	profileId = profileId.id;

	async function getData(url = "", methods = "") {
		const response = await fetch(url, {
			method: methods,
			headers: {
				"Content-Type": "application/json",
				authorization: localStorage.getItem("auth_token") || sessionStorage.getItem("auth_token"),
			},
		});
		return response.json();
	}

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [image, setImage] = useState("");
	const [About, setAbout] = useState("");
	const [postData, setPostData] = useState([]);
	const [albumData, setAlbumData] = useState([]);
	const [count, setCount] = useState({});
	const [followShow, setFollowShow] = useState(false);
	const [followType, setFollowType] = useState("");
	const [e, setBottom] = useState("");
	const [ScrollBottom, setScrollBottom] = useState(false);
	const [page, setPage] = useState(0);
	const [reload, setReload] = useState(false);
	const [userType, setUserType] = useState("");
	const [courseData, setCourseData] = useState([]);
	const [rating, setRating] = useState("");
	const [activeUserId, setActiveUserId] = useState("");

	useEffect(() => {
		// setReload(true);
		getData(process.env.REACT_APP_BASE_URL + "/auth/get-user-details?user_id=" + profileId, "GET").then((data) => {
			if (data.status == true) {
				setName(data.data.data.full_name);
				setEmail(data.data.data.email);
				setImage(data.data.data.pic);
				setAbout(data.data.data.description);
				// console.log("data=> ", data);
				setUserType(data.data.data.user_type);
				setRating(data.data.data.rating);
				setActiveUserId(data.data.data._id);
			} else {
				console("incorrect");
			}
		});
		getData(process.env.REACT_APP_BASE_URL + "/album/get?offset=0&user_id=" + profileId, "GET").then((data) => {
			if (data.status == true) {
				setAlbumData(data.data);
			} else {
				console("incorrect");
			}
		});
		getData(process.env.REACT_APP_BASE_URL + "/class/getclassByUserid/" + profileId, "GET").then((data) => {
			if (data.status == true) {
				setCourseData(data.data);
			} else {
				console("incorrect");
			}
		});
		getData(`${process.env.REACT_APP_BASE_URL}/post/get-post-by-user/${profileId}?offset=0&type=`, "GET").then((data) => {
			if (data.status == true) {
				setPostData(data.data.data);
				setCount(data.data.count);
			} else {
				console("incorrect");
			}
		});
	}, [profileId]);

	function ReachBottom(e) {
		const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
		if (bottom) {
			setPage((prev) => prev + 10);
		}
	}

	const [post, setPost] = useState(true);
	const [album, setAlbum] = useState(false);
	const [course, setCourse] = useState(false);

	const handelFollow = () => {
		axios
			.post("/auth/follow-unfollow-user", {
				user_id: profileId,
			})
			.then(({ data }) => {
				if (data.status) {
					console.log(data);
					if (data.message === "unfollow") {
						dispatch(setIsFollow(false));
						createNotification(userId, "Follow", `You unfollow ${name}`);
					} else if (data.message === "follow") {
						// toast.success(`You started following ${name}`);
						createNotification(profileId, "Follow", `${user.full_name} started following you`);
						createNotification(userId, "Follow", `You started following ${name}`);
						dispatch(setIsFollow(true));
					}
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const handelUnFollow = () => {
		if (isFollow) {
			swal({
				title: "Do you want to Unfollow this user?",
				buttons: true,
			}).then((confirmed) => {
				if (confirmed) {
					axios
						.post("/auth/follow-unfollow-user", {
							user_id: profileId,
						})
						.then(({ data }) => {
							if (data.status) {
								console.log(data);
								if (data.message === "unfollow") {
									dispatch(setIsFollow(false));
									createNotification(userId, "Follow", `You unfollow ${name}`);
								} else if (data.message === "follow") {
									toast.success(`You started following ${name}`);
									createNotification(profileId, "Follow", `${user.full_name} started following you`);
									createNotification(userId, "Follow", `You started following ${name}`);
									dispatch(setIsFollow(true));
								}
							}
						})
						.catch((e) => {
							console.log(e);
						});
				}
			});
		}
	};

	console.log("isFollow=> ", isFollow);

	return (
		<div className="MainContainer">
			{localStorage.getItem("user_type") == "user" ? <Header /> : ""}
			{isMessageOpen && <MessagePopup />}
			{followShow && <FollowList setFollowShow={setFollowShow} followType={followType} userId={profileId} />}
			<div className="BasicStyle accountContainer home BasicStyle-professional">
				<div className="account"></div>

				<div className="home-right-mid">
					<div className="display-flex" style={{ gap: "40px" }}>
						<div className="UsersProfileDetailsContainer">
							<div className="ProfilePicContainer">
								<img
									src={image == "" ? userDefaultImage : image}
									alt=""
									style={{ marginTop: "-10%" }}
									className="round-img"
								/>
							</div>
							<div className="UserProfileNameContainer">
								<h3>{name}</h3>
								<button
									className={styles.FollowBtn}
									type="button"
									onClick={isFollow ? handelUnFollow : handelFollow}>
									{isFollow ? "Following" : "Follow"}
								</button>
							</div>
							<div className="RatingMessageContainer">
								<div className="RatingContainer">Profile Rating: {rating}</div>
								<div
									className="MessageContainer"
									onClick={() =>
										dispatch(
											setMessage({
												open: true,
												id: activeUserId,
											})
										)
									}>
									Message
								</div>
							</div>
							<div className={styles.belowTopBtn}>
								<h3>
									{count.post}
									<span>Post</span>
								</h3>
								<h3
									onClick={() => {
										setFollowType("Followers");
										setFollowShow(true);
									}}>
									{count.follow}
									<span>Following</span>
								</h3>
								<h3
									onClick={() => {
										setFollowType("Following");
										setFollowShow(true);
									}}>
									{count.followiing}
									<span>Followers</span>
								</h3>
							</div>
							<div>
								<h5 className="text-secondary" style={{ marginTop: "10px", textDecoration: "underline" }}>
									About
								</h5>
								<p>{About ? About : <>Not Available</>}</p>
							</div>
							<div className="ProfileButtonContainer">
								{/* <button className="btn btn-outline-secondary">Digital Visiting Card</button>
								<button className="btn btn-outline-secondary">My Purchase</button> */}
								<button className="btn btn-outline-secondary" onClick={() => navigate("/support")}>
									Support & Help
								</button>
								<button className="btn btn-outline-secondary" onClick={() => navigate("/privacy")}>
									Privacy Policy
								</button>
								<button className="btn btn-outline-secondary" onClick={() => navigate("/terms-condition")}>
									Terms And Conditions
								</button>
								<button className="btn btn-outline-secondary" onClick={() => navigate("/aboutUs")}>
									About
								</button>
								{/* <button className="btn btn-outline-secondary">Rating</button> */}
							</div>
						</div>

						<div>
							<br />
							<div className="display-flex">
								<div
									style={{ cursor: "pointer" }}
									className={post ? "primary-color" : ""}
									onClick={() => {
										setPost(true);
										setAlbum(false);
										setCourse(false);
									}}>
									Post
								</div>
								&nbsp;&nbsp;&nbsp;&nbsp;
								{userType === "user" ? (
									<div
										style={{ cursor: "pointer" }}
										className={album ? "primary-color" : ""}
										onClick={() => {
											setPost(false);
											setAlbum(true);
											setCourse(false);
										}}>
										Album
									</div>
								) : (
									<>
										&nbsp;&nbsp;&nbsp;&nbsp;
										<div
											style={{ cursor: "pointer" }}
											className={course ? "primary-color" : ""}
											onClick={() => {
												setPost(false);
												setAlbum(false);
												setCourse(true);
											}}>
											Course
										</div>
									</>
								)}
							</div>

							<br />

							<div className="postAndAlbumContainer" style={{ display: "block" }}>
								{post && (
									<div className="PostContainerImage" onScroll={ReachBottom}>
										{postData.length == 0 ? (
											<p style={{ color: "white", fontSize: "30px" }}>No Post</p>
										) : (
											<PostForUser
												profileId={profileId}
												page={page}
												setPage={setPage}
												reload={reload}
												setReload={setReload}
											/>
										)}
									</div>
								)}
								{album && (
									<div className="albumContainerImage">
										{albumData ? (
											albumData.map((item, index) => (
												<div
													key={index}
													className={styles.albumBox}
													onClick={() => navigate(`/album/${item._id}`)}>
													<img src={item.cover_photo} alt="" />
													<div>
														<h4>{item.title}</h4>
														<p>{item.description}</p>
													</div>
												</div>
											))
										) : (
											<p style={{ color: "white" }}>No Album</p>
										)}
									</div>
								)}
								{course && (
									<div className="albumContainerImage">
										{courseData.length > 0 ? (
											courseData.map((item, index) => (
												<div
													key={index}
													className={styles.albumBox}
													onClick={() => navigate(`/learning/CourseDetails/${item._id}`)}>
													<img src={item.coverpic} alt="" />
													{console.log(item)}
													<div>
														<h4>{item.courseTitle}</h4>
														<p>{item.courseDescription}</p>
													</div>
												</div>
											))
										) : (
											<p style={{ color: "white" }}>No Course</p>
										)}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default UsersProfile;
